import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "junior-sabbatical",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#junior-sabbatical",
        "aria-label": "junior sabbatical permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Junior Sabbatical`}</h2>
    <div {...{
      "className": "admonition admonition-note alert alert--secondary"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M6.3 5.69a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 7.99c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V7.98v.01zM7 2.3c-3.14 0-5.7 2.54-5.7 5.68 0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 .98c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"
              }}></path></svg></span>{`UPDATE`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`I will be on `}<a parentName="p" {...{
            "href": "https://sbs.arizona.edu/sites/sbs.arizona.edu/files/Junior%20Professional%20Development%20Leave%20SBS_1.pdf",
            "target": "_self",
            "rel": "nofollow"
          }}><strong parentName="a">{`junior faculty professional development leave`}</strong></a>{` ("junior sabbatical") from the University of Arizona for the Spring 2025 semester. During this leave, I will not be checking my UA inbox regularly. I will return in July of 2025.`}</p></div></div>
    <p>{`For University of Arizona matters not addressed specifically below, please contact the current department head for Linguistics, `}<a parentName="p" {...{
        "href": "https://linguistics.arizona.edu/person/natasha-warner",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Dr. Natasha Warner`}</a>{` (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`nwarner`}</code>{` AT `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`arizona`}</code>{` DOT `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`edu`}</code>{`).`}</p>
    <h1 {...{
      "id": "academic-programs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#academic-programs",
        "aria-label": "academic programs permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Academic programs`}</h1>
    <h2 {...{
      "id": "hlt-online",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#hlt-online",
        "aria-label": "hlt online permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HLT Online`}</h2>
    <p><a parentName="p" {...{
        "href": "https://calendly.com/meet_with_eric",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Dr. Eric Jackson`}</a>{` (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ejackson`}</code>{` AT `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`arizona`}</code>{` DOT `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`edu`}</code>{`) is serving as the director of the online HLT MS program this AY.  Questions about the program (admissions, course scheduling, etc.) should be directed to him. `}</p>
    <h2 {...{
      "id": "graduate-certificate-in-nlp",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#graduate-certificate-in-nlp",
        "aria-label": "graduate certificate in nlp permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Graduate Certificate in NLP`}</h2>
    <p>{`Questions about the `}<a parentName="p" {...{
        "href": "https://grad.arizona.edu/catalog/programinfo/NLPCRTG",
        "target": "_self",
        "rel": "nofollow"
      }}>{`graduate certificate in NLP`}</a>{` can be forwarded to Joshua Carlin (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`jcarlin117`}</code>{` AT `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`arizona`}</code>{` DOT `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`edu`}</code>{`).`}</p>
    <h1 {...{
      "id": "something-else",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#something-else",
        "aria-label": "something else permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Something else?`}</h1>
    <p>{`For unrelated matters, please feel free to contact me using my personal email address.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      